<template>
  <section class="page home-page">
    <section class="pro_layout">
      <loading-indicator v-if="!symbols" />
      <div v-else>
        <div class="home-top">
          <top-banners
            v-if="context.homeConfig"
            :home_config="context.homeConfig"
          />
        </div>
                        <!-- major nav icons for sm screens -->
        <div class="d-block d-md-none sm-navs">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <router-link to="/user/login">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-login" />
                                    </svg>
                                    {{ $t('general.login') }}/{{ $t('general.register') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <!-- Display a tab for the savings feature when it was enabled. -->
                                <router-link v-if="sysconfig.savingsEnabled" to="/saving">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t('home.header_saving', { platform: sysconfig.name }) }}
                                </router-link>
                                <router-link v-else to="/user/balance">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t(sysconfig.is_hkmy ? 'home.header_buy_crypto_my' : 'home.header_buy_crypto') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/startup">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-assets" />
                                    </svg>
                                    {{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/notices">
                                    <div class="svg-box"> <svg class="svg-icon notice-svg" viewBox="0 0 256 256">
                                            <use class="use-svg" xlink:href="/dist/svg/icons.svg#v4.2-notice" />
                                        </svg>
                                    </div>
                                    {{ $t('notices.notice_title') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
        </div>
        <!-- <bottom-nav class="home-nav" :active_tab="active_tab" /> -->
        <section class="content-first container">
          <div class="yellow">
            <div class="title">{{$t('home.content_first_title1')}}</div>
            <div class="text">{{$t('home.content_first_desp1',{platform:sysconfig.name})}}</div>
          </div>
          <div class="red">
            <div class="title">
              <div class="right"> {{$t('home.content_first_title2')}}</div>
              <div class="left">
                <img src="@/assets/images/EA/progress.png" />
              </div>
            </div>
            <div class="text">{{$t('home.content_first_desp2')}}</div>
          </div>
          <div class="blue">
            <div class="title">{{$t('home.content_first_title3')}}</div>
            <div class="text">{{$t('home.content_first_desp3')}}</div>
          </div>
        </section>
        <div class="page-part" v-if="context.homeConfig && context.homeConfig.promptedNotices && context.homeConfig.promptedNotices.length">
            <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
        </div>
        <inline-svg-icons :symbols="symbols" />
        <section class="chosen container">
           <div class="title">{{$t("home.home_chosen_title")}}</div>
           <div class="content">
              <div class="first">
                <div class="one"></div><div  class="two">{{$t("home.home_chosen_card1")}}</div><div  class="three">{{$t("home.home_chosen_card2")}}</div><div  class="forth"></div>
              </div>
              <div class="second">
                <div class="one">{{$t("home.home_chosen_card3")}}</div><div  class="two">{{$t("home.home_chosen_card4")}}</div><div  class="three">{{$t("home.home_chosen_card5")}}</div>
              </div>
              <div class="third">
                <div class="one">{{$t("home.home_chosen_card6")}}</div><div  class="two">{{$t("home.home_chosen_card7")}}</div><div  class="three">{{$t("home.home_chosen_card8")}}</div>
              </div>
           </div>
        </section> 
        <div class="container ensures">
          <div class="title">{{ $t('home.home_ensures') }}</div>
          <div class="content">
            <div class="top">
               <div class="left">
                    <div class="title"><img src="@/assets/images/EA/ensures1.png" /><span>{{ $t('home.home_ensures1') }}</span></div>
                    <div class="text">{{ $t('home.home_ensures1_decript', { platform: sysconfig.name })}}</div>
               </div>
               <div class="right">
                    <div class="title"><img src="@/assets/images/EA/ensures2.png" /><span>{{ $t('home.home_ensures2') }}</span></div>
                    <div class="text">{{ $t('home.home_ensures1_decript2') }}</div>
               </div>
            </div>
            <div class="down">
               <div class="left">
                    <div class="title"><img src="@/assets/images/EA/ensures3.png" /><span>{{ $t('home.home_ensures3') }}</span></div>
                    <div class="text">{{ $t('home.home_ensures1_decript3', { platform: sysconfig.name }) }}</div>
               </div>
               <div class="right">
                    <div class="title"><img src="@/assets/images/EA/ensures4.png" /><span>{{ $t('home.home_ensures4') }}</span></div>
                    <div class="text">{{ $t('home.home_ensures1_decrip4', { platform: sysconfig.name }) }}</div>
               </div>
            </div>
          </div>
         </div>
        <div class="quotes container">
          <div class="col">
              <h2 class="table-title">
                  {{ $t('home.risk_notice_line1') }}
              </h2>
              <div class="main-block-wrapper">
                  <home-quotes ref="quotes" :symbols="symbols" />
              </div>
          </div>
        </div>
        <div class="promblem container">
          <img src="@/assets/images/EA/qamark.png" class="left" /> 
          <img src="@/assets/images/EA/mark.png" class="right" />
          <div class="table-title">
                {{ $t('home.home_step') }}
          </div>
          <div class="promblem-box">
            <div class="promblem-left">
              <div class="lggrren content">
                <div class="one">
                  {{ $t('home.home_step_title1') }}
                  <div>{{ $t('home.home_step_desp1') }}</div>
                </div>
                <div class="two">
                  {{ $t('home.home_step_title2') }}
                  <div>{{ $t('home.home_step_desp2') }}</div>
                </div>
                <div class="three">
                  {{ $t('home.home_step_title3') }}
                  <div>{{ $t('home.home_step_desp3') }}</div>
                </div>
                <div class="four">{{ $t('home.home_step_desp4',{platform:sysconfig.name}) }}</div>
              </div>
              <div class="lmgrren content">
                <div>
                  <div class="one">{{ $t('home.home_step_title1') }}
                    <div>{{ $t('home.home_step_desp1') }}</div>
                  </div>
                  <div class="two">{{ $t('home.home_step_title2') }}
                    <div>{{ $t('home.home_step_desp2') }}</div>
                  </div>
                </div>
                <div>
                  <div class="three">{{ $t('home.home_step_title3') }}
                    <div>{{ $t('home.home_step_desp3') }}</div>
                  </div>
                  <div class="four">{{ $t('home.home_step_desp4',{platform:sysconfig.name}) }}</div>
                </div>    
              </div>
             <div class="check">
                <div>
                    <router-link to="/quotes" class="d-flex align-items-center">
                            {{$t("home.home_check")}}
                    </router-link>
                </div>
           </div>
            </div>
            <div class="promblem-right">
              <div class="container beauty">
                <div class="left">
                  <h4>{{ $t('home.home_platform_title') }}</h4>
                  <div class="text">{{ $t('home.home_platform_desp',{platform:sysconfig.name}) }}</div>       
                </div>
                <!-- <div class="foot-hleps">{{ $t("helps.title")}}</div> -->
              </div>
              <div class="click-event">
                <div class="trde" @click="goToTradePage()">{{ $t("general.trade")}}</div>
                <a class="hleps" href="javascript:;" onclick="toggle_udesk()">{{ $t("helps.title")}}</a>
              </div>
            </div>
          </div>
        </div>
        <partner-links
          v-if="
            context && context.homeConfig && context.homeConfig.spl === true
          "
        />
      </div>
    </section>
  </section>
</template>

<style scoped lang="less">
 /* **首页导航 */
.home-page .pro_layout .sm-navs {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    display: none !important;
}
.sm-navs a {
    color: #000;
    font-size: 12px;
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
}

.sm-navs svg.svg-icon {
    display: block;
    height: 1.5rem;
    width: auto;
    margin: auto;
    margin-bottom: 0.5rem;
    fill: var(--primary-base);
}
.sm-navs .container .row >.col {
    width: 25%;
}
.svg-box {
    height: 1.5rem;
    position: relative;
    margin-bottom: 0.5rem;
}

.sm-navs svg.svg-icon.notice-svg {
    height: 2.8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.home-nav{
  position:static ;
}
.notice{
    background: #D8D8D8 ;
    padding-top: 0px;
    color: #3D3D3D ;
}
.marquee{
  display: flex;
  align-items: center;
  height: 100%;
}
.content-first{
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  >div{
    width: 30%; 
    min-height: 200px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 13px;
    padding: 10px;
    margin-right: 10px;
  }
  .yellow{
    background-color:#FFE9CA;
  }
  .red{
    background-color:#F9DDF6;
  }
  .blue{
    background-color:#DBECFE;
  }
  .title{
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #000;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
  }
  .text{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    margin-top: 10px;
  }
  .title .right {
    width: 85%;
    text-align: center;
    margin-left: 15%;
  }
  .title .left {
    width: 15%;
    text-align: right;
  }
}
.ensures{
  margin-top: 30px;
  margin-bottom: 30px;
  .title{
      font-size: 20px;
      color: #081035;
      font-family: PingFang SC, PingFang SC;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
  }
  .content{
    .top ,.down{
        display: flex;
        margin-bottom: 30px;
        .left,.right{
            border-radius: 13px;
            padding: 15px;
            height: 100%;
            width: 48%;
            margin-right: 4%;
            min-height: 360px;
            .title{
                width: 100%;
                height: 66px;
                display: flex;
                img{
                    height: 100%;
                    width: 66px;
                }
                span{
                    display: inline-block;
                    width: calc(100% - 67px) ;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 32px;
                    font-size: 18px;
                }
            }
            .text{
                margin-top: 10px;
                width: 100%;
                height: calc(100% - 66px) ;
                color: #000;
                font-size: 14px;
            }
        }
        .right{
            width: 48%;
        }
    }
    .top .left{
        background: #DBECFEFF;
    }
    .top .right{
        background: #FFE9CAFF;
    }
    .down .left{
        background: #CAE594FF;
    }
    .down .right{
        background: #F9DDF6FF;
    }
  }
}
.beauty{
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  .foot-hleps{
    display: none;
  }
  .left{
    width: 50%;
    height: 400px;
    color: #000;
    padding-top: 25%;
    h4{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      margin-top: 10px;
    }
    .text{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
    .imgs{
      display: flex;
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
  .right{
    width: 50%;
    height: 380px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .chilren{
      width: 350px;
      height: 300px;
      background: #F9DDF6;
      position: absolute;
      bottom: 0px;
      .img{
        height: 350px;
        position: absolute;
        top: -50px;
        width: 350px;
      }
    }
  }
  .foot-hleps{
    display: none;
  }
}
.chosen{
  margin-top: 40px;
  .title{
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #081035;
    font-family: PingFang SC, PingFang SC;
    font-weight: 700;
    width: 100%;
    padding: 0 20px;
  }
  .content{
    width: 100%;
    height: 300px;
    padding: 10px 0;
    font-size: 12px;
    .first{
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: flex;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .one{
        width: 12%;
        background-color: #F6F6F6 ;
        height: 100%;
        border-radius: 7px;
        margin-right: 4%;
      }
      .two{
        width: 31%;
        background-color: #F9DDF6 ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        margin-right: 4%;
      }
      .three{
        width: 31%;
        background-color: #DBECFE ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .forth{
        width: 12%;
        background-color: #F6F6F6 ;
        height: 100%;
        border-radius: 7px;
      }
    }
    .second{
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: flex;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .one{
        width: 31%;
        background-color: #FFE9CA ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .two{
        width: 31%;
        background-color: #CAE594 ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .three{
        width: 31%;
        background-color: #F9DDF6 ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
      }
    }
    .third{
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: flex;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .one{
        width: 31%;
        background-color:  #DBECFE;
        color: #1C2125;
        height: 100%;
        border-radius: 7px;
        margin-right: 4%;
      }
      .two{
        width: 31%;
        background-color: #FFE9CA ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .three{
        width: 31%;
        background-color: #CAE594 ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
      }
    }
  }
}
.quotes{ 
  margin-bottom: 100px;
    .table-title{
      font-size: 20px;
      color: #081035;
      font-family: PingFang SC, PingFang SC;
      font-weight: 700;
      text-align: center;
    }
    .container{
       background: url(@/assets/images/EA/map@2x.png) no-repeat;
       background-size: 100% 100%;
    }
}
.promblem{
   position: relative;
   background: #F8F8F8;
   border-radius: 20px 20px 20px 20px;
   margin-bottom: 30px;
   padding-top: 20px;
  .promblem-box {
    display: flex;
    .promblem-left,.promblem-right {
      flex: 1;
      padding-bottom: 20px;
    }
  }
  .promblem-right{
    position: relative;
    .click-event{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 20px;
        right: 0px;
        left: 0px;
        text-align: center;
        .trde{
            margin-top: 30px;
            width: 90px;
            height: 30px;
            background: #000;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            height: 40px;
            margin-right: 20px;
        }
        .hleps{
            margin-top: 30px;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 80px;
            height: 40px;
        }
    }
  }
  .table-title{
    font-size: 20px;
    color: #081035;
    font-family: PingFang SC, PingFang SC;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .check{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    >div{
      width: 90px;
      height: 40px;
      border-radius: 20px;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        color: #fff;
      }
    }
  }
  @media ( min-width :1024px) {
    .lmgrren{
      display: none;
    }
    .lggrren{
      display: block;
    }
  }
  .lggrren{
    width: 100%;
    margin-top: 20px;
    >div{
      width: 50%;
      margin: auto;
      color: #111;
      border-radius: 7px;
      text-align: center;
      margin-bottom: 5px;
      padding: 5px;
      min-height: 140px;
    }
    .one{
      background: #F9DDF6;
    }
    .two{
      background: #DBECFE;
    }
    .three{
      background: #FFE9CA;
    }
    .four{
      background: #CAE594 ;
    }
  }
  @media ( max-width :1024px) {
    .check{
      display: none;
    }
    .lmgrren{
      display: block;
      >div{
        color: #111;
        width: 100%;
        display: flex;
        flex: row;
        >div{
          width: 40%;
          margin: auto;
          min-height: 230px;
          margin-bottom: 20px;
          border-radius: 7px;
          padding: 5px;
          word-wrap: break-word;
        }
        .one{
          background: #F9DDF6;
        }
        .two{
          background: #DBECFE;
        }
        .three{
          background: #FFE9CA;
        }
        .four{
          background: #CAE594 ;
        }
      }
    }
    .lggrren{
      display: none;
    }
  }
}
@media ( max-width :1024px) {
  .content-first{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .content-first>div{
    width: 90%;
    min-height: 200px;
    margin: auto;
    margin-bottom: 10px;
    padding: 10px;
  }
  .chosen{
    .content{
      padding: 10px 20px;
      font-size: 8px;
    }
  }
  .beauty{
    display: flex;
    flex-direction: column;
    .right{
      width: 100%;
      justify-content: flex-start;
    }
    .left .hleps{
        display: none;
      } 
    .left{
      width: 100%;  
      text-align: center; 
    }
    .foot-hleps{
      display: block;
      margin-top: 20px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 40px;
      color: #000;
    }
  }
  .promblem .promblem-right .click-event{
     position: unset;
     text-align: center;
  }
}
@media ( max-width :1024px) {
  .promblem .promblem-right .click-event{
        display: flex;
        align-items: center;
        justify-content: center;
        .trde{
            margin-right: 20px;
        }
  }
  .promblem{
    .promblem-box {
       flex-direction: column;
       .beauty .left{
          padding-top: 10px;
          height: 100px;
       }
    }
  }
  .ensures{
        .title{
            text-align: center;
            margin-bottom: 20px;
        }
        .content{
            .top ,.down{
                display: flex;
                flex-direction: column;
                .left,.right{
                    width: 100%;
                }
                .left{
                    margin-bottom: 20px;
                }
            }
        }
    }
}
@media screen and ( min-width :1024px) and ( max-width :1024px) {
    .d-md-none {
        display: block !important;
    }
}
@media screen and ( min-width :375px) and ( max-width :995px) {
  .ensures .content{
    .top ,.down{
       .left,.right{
          min-height: 360px;
       }
    }
  }
}
@media ( min-width :995px) {
  .ensures .content{
    .top ,.down{
       .left,.right{
          min-height: 300px;
       }
    }
  }
}
</style>

<script type="text/javascript">
import TopBanners from "./Components/TopBanners-4.2.vue";
import HomeQuotes from './Components/HomeQuotes.vue';
import PartnerLinks from "./Components/_PartnerLinks-4.2.vue";
import "@/assets/svg/icon-login.svg";
import "@/assets/svg/icon-wallet.svg";
import "@/assets/svg/icon-assets.svg";
import "@/assets/svg/icon-invite.svg";
import "@/assets/svg/icon-notice.svg";

import "@/assets/svg/v4.2/v4.2-login.svg";
import "@/assets/svg/v4.2/v4.2-wallet.svg";
import "@/assets/svg/v4.2/v4.2-assets.svg";
import "@/assets/svg/v4.2/v4.2-notice.svg";
import HorizontalNotices from './Components/_ScrollNotices_Horizontal.vue';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import HomeNotices from './Components/HomeNotices.vue';
import BottomNav from '../Components/BottomNav-4.2.vue';
import { SymbolInfo } from "utilities/SymbolInfo.js";
import { getSymbolManagerAsync, getAppContext } from "utilities/helper";
let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
  components: {
    TopBanners,
    HomeQuotes,
    PartnerLinks,
    HorizontalNotices,
    InlineSvgIcons,
    HomeNotices,
    BottomNav
  },
    data() {
        return {
            // All symbols.
            symbols: null,
            // App context
            context: null,
            active_tab: '/',
        };
    },

    computed: {
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        }
    },

    created() {
        g_destoryed = false;
        this.context = getAppContext();
        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }
        },
        goToTradePage() {
            this.$router.push('/futures')
        },
        syncQuotes: function () {
            const self = this;
            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.promptedSymbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                    .then((json) => {
                        const quotes = json.data;
                        if (quotes.length > 0) {
                            for (let i = 0; i < quotes.length; i++) {
                                const quote = quotes[i];
                                const sym = g_symbolMap[quote.id];
                                if (sym) {
                                    sym.update(quote);
                                }
                            }
                        }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }
    }
};
</script>
