<template>
    <div ref="exchangeModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <form action="javascript:void(0);" method="post" @submit="doTransfer">
                    <!-- modal header -->
                    <div class="modal-header">
                        <h4 class="modal-title">{{ $t('assets.label_exchange') }}</h4>
                    </div>

                    <!-- modal body -->
                    <div class="modal-body">
                         <div class="form-group">
                            <label class="form-label"> {{ $t('assets.label_transfer_from') }} </label>
                            <select v-model="from_token" name="From" class="form-select" data-val="true" data-val-required="*">
                                <option value="USDT">USDT</option>
                                <option value="FTUSDT">{{ $t('assets.label_futures_account') }}</option>
                                <option v-if="bonusToken" :value="bonusToken">{{ bonusToken }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="form-label"> {{ $t('assets.label_transfer_to') }} </label>
                            <select v-model="to_currency" name="To" class="form-select" data-val="true" data-val-required="*">
                                <option value="USDT" :disabled="to_currency !== 'USDT'">USDT</option>
                                <option value="FTUSDT" :disabled="to_currency !== 'FTUSDT'">{{ $t('assets.label_futures_account') }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="form-label">{{ $t('assets.label_transfer_amount') }} </label>
                            <div class="input-group">
                                <input type="number" v-model="transfer_amount" name="Amount" class="form-control" data-val="true" data-val-required="*" autocomplete="off" />
                                <div class="input-group-append">
                                    <a @click="fillAvailableBalance" href="javascript:;" class="btn btn-primary">{{ $t('assets.label_transfer_all') }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="help-block">
                                {{ $t('assets.label_available_amount') }}
                                <span v-if="!isNaN(available_amount)">{{ available_amount }}</span>
                        </div>
                    </div>

                    <!-- modal footer -->
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :disabled="submitting" :class="{ 'btn-loading': submitting }">{{ $t('general.confirm') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Index from '../../BinaryOption/Index.vue';
import { Modal } from 'bootstrap';

export default {
    components: { Index },
    props: ['balances', 'bonusToken'],

    data() {
        return {
            from_token: 'USDT',
            to_currency: 'FTUSDT',
            transfer_amount: '',
            submitting: false,

            available_amount: NaN,

            // The popup modal instance.
            popupModal: null
        };
    },

    watch: {
        from_token: function (new_val, old_val) {
            switch (this.from_token) {
                case 'USDT':
                    this.to_currency = 'FTUSDT';
                    break;

                case 'FTUSDT':
                case 'INCT':
                case this.bonusToken:
                    this.to_currency = 'USDT';
                    break;

                default:
                    this.from_token = old_val;
                    throw new Error('Not supported');
            }

            this.available_amount = this.getAvailableAmount(this.from_token);
        }

        // to_currency: function () {
        //     if (this.from_token === this.to_currency) {
        //         this.from_token = this.to_currency === 'USDT' ? 'FTUSDT' : 'USDT';
        //     }
        // }
    },

    mounted() {
        $.resetValidators();

        this.popupModal = new Modal(this.$refs.exchangeModal);
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        showModal: function () {
            this.available_amount = this.getAvailableAmount(this.from_token);

            // const modal = bootstrap.Modal.getOrCreateInstance(this.$refs.exchangeModal);
            // modal.show();
            this.popupModal.show();
        },

        doTransfer: function (e) {
            const frm = $(e.target);
            const self = this;

            if (frm.valid()) {
                var data = frm.serializeAsJson();

                if (data.From !== data.To) {
                    self.submitting = true;

                    $.callPostApi(self, '/api/v1/balance/transfer', data)
                        .then((json) => {
                            self.submitting = false;
                            if (json.errcode == 0) {
                                $.top_alert(self.$t('general.submitted'));

                                // Notify the parent once the balance has been updated.
                                self.$emit('balance-updated');

                                // Close the modal
                                Vue.nextTick(() => {
                                    // $(self.$refs.exchangeModal).modal('hide');
                                    self.popupModal.hide();
                                });
                            } else {
                                $.top_error(json.errmsg);
                            }
                        })
                        .catch((err) => {
                            self.submitting = false;
                            $.top_error(self.$t('general.operation_error'));
                        });
                }
            }
        },

        fillAvailableBalance: function () {
            const amt = this.getAvailableAmount(this.from_token);
            this.transfer_amount = isNaN(amt) ? '' : amt;
        },

        getAvailableAmount: function (currency) {
            if (this.balances) {
                for (let i = 0; i < this.balances.length; i++) {
                    const entity = this.balances[i];
                    if (entity.currency === currency) {
                        return entity.available;
                    }
                }
            }
            return NaN;
        }
    }
};
</script> 
<style scoped>
.modal-footer .btn-primary{
   border-radius: 0px;
   border: 1px solid rgba(0,0,0,0.9);
   font-family: PingFangSC, PingFang SC;
   color: #fff;
   background: #041438;
   cursor: pointer;
   width: 96%;
}
.modal-footer{
    display: flex;
    justify-content: center;
}
.input-group-append .btn-primary{
    background: #fff;
    color: #006CFF;
}
.modal-header{
    position: relative;
}
.modal-header .close{
    position: absolute;
    right: 20px;
    border: 1px solid #999;
    font-size: 16px;
}
</style>